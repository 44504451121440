<template>
  <el-main class="center-container-MT">
    <div class="center-layout-MT">
      <div class="body-title-layout" style="padding-left: 0">
        <div class="divider-3"></div>
        <div class="body-preview-title-content-style">考试题库</div>
      </div>
      <el-scrollbar class="record-items-container">
        <div class="record-item-container"
             v-for="(item,index) in examList"
             :key="index"
             :style="index===0?'margin-top:0;':'margin-top: 25px;'">
          <div class="time-record-layout">
            <div class="origin-style-TR"></div>
            <div class="time-content-TR">更新于 {{ item.createTime }}</div>
          </div>
          <div class="test-record-Info-container" @click="getAnswerContent(item.id)">
            <div class="test-problem-layout-TRI" style="padding-left: 30px;padding-top:0;line-height: 100px;">
              <span class="type-TR-TRI" style="line-height: 100%;">{{ item.examName }}</span>
            </div>
          </div>
        </div>
      </el-scrollbar>
    </div>
  </el-main>
</template>
<script>
import Answer from "@/view/Answer";

export default {
  name: "ExamDB",
  data() {
    return {
      examList: []
    }
  },
  methods: {
    getExamList() {
      this.axios.get('/jeecg-boot/humen/humenExam/list').then(res => {
        this.examList = res.result.records
      })
    },
    getAnswerContent(id) {
      this.$router.push({path: '/answer', query: {id: id}})
    }
  },
  mounted() {
    //获取题库列表
    this.getExamList()
  }
}
</script>

<style scoped>

</style>
